class DataSpikeFilter {
  constructor(config = {}) {
    // Enhanced default configuration
    this.config = {
      // Fields to analyze can now be an array of strings or objects with field name and settings
      fieldsToAnalyze: config.fieldsToAnalyze || ['value'],
      interpolateSpikes: config.interpolateSpikes !== undefined ? config.interpolateSpikes : true,
      // Add sensitivity multiplier for UCL calculation (default 2.66 is standard statistical value)
      sensitivityMultiplier: config.sensitivityMultiplier || 2.66,
      // Minimum number of values needed to calculate statistics
      minDataPoints: config.minDataPoints || 3
    };
  }


  // Calculate the average of an array of numbers, handling null/undefined values
  calculateAverage(values) {
    const validValues = values.filter(val => val !== null && val !== undefined);
    if (validValues.length === 0) return 0;
    return validValues.reduce((acc, val) => acc + val, 0) / validValues.length;
  }

  // Calculate the moving range (MR) between consecutive valid values
  calculateMovingRange(values) {
    const validRanges = [];
    for (let i = 1; i < values.length; i++) {
      const current = values[i];
      const previous = values[i - 1];
      if (current !== null && current !== undefined && 
          previous !== null && previous !== undefined) {
        validRanges.push(Math.abs(current - previous));
      }
    }
    return validRanges;
  }

  // Get valid value from data point for a given field
  getFieldValue(dataPoint, field) {
    return dataPoint[field];
  }

  // Main method to filter spikes using the UCL formula
  filterSpikes(data) {
    if (!Array.isArray(data) || data.length < this.config.minDataPoints) {
      throw new Error(`Data must be an array with at least ${this.config.minDataPoints} measurements`);
    }

    const filteredData = JSON.parse(JSON.stringify(data)); // Deep copy of the data

    // Process each field to analyze
    this.config.fieldsToAnalyze.forEach(field => {
      // Extract values for this field
      const fieldValues = data.map(d => this.getFieldValue(d, field));
      
      // Calculate statistics
      const avgTotal = this.calculateAverage(fieldValues);
      const movingRange = this.calculateMovingRange(fieldValues);
      const avgMR = this.calculateAverage(movingRange);

      // Calculate UCL (Upper Control Limit)
      const ucl = avgTotal + (this.config.sensitivityMultiplier * avgMR);
      // Calculate LCL (Lower Control Limit)
      const lcl = avgTotal - (this.config.sensitivityMultiplier * avgMR);

      // Process each measurement
      filteredData.forEach((measurement, index) => {


        const value = this.getFieldValue(measurement, field);

        if(value === null || value === undefined) {
          return;
        };

        // Check if the value is outside control limits
        if (value > ucl || value < lcl) {
          if (this.config.interpolateSpikes) {
            // Find previous and next valid values for interpolation
            let prevIndex = index - 1;
            let nextIndex = index + 1;

            // Look for the previous and next values that are within control limits
            while (prevIndex >= 0 && 
                   (this.getFieldValue(filteredData[prevIndex], field) > ucl || 
                    this.getFieldValue(filteredData[prevIndex], field) < lcl)) {
              prevIndex--;
            }
            while (nextIndex < filteredData.length && 
                   (this.getFieldValue(filteredData[nextIndex], field) > ucl || 
                    this.getFieldValue(filteredData[nextIndex], field) < lcl)) {
              nextIndex++;
            }

            const prevValue = prevIndex >= 0 ? this.getFieldValue(filteredData[prevIndex], field) : value;
            const nextValue = nextIndex < filteredData.length ? this.getFieldValue(filteredData[nextIndex], field) : value;

            // Interpolate the value
            filteredData[index][field] = this.interpolateValue(prevValue, nextValue);
          } else {
            // If interpolation is disabled, set the spike to null
            filteredData[index][field] = null;
          }
        }
      });
    });

    return filteredData;
  }

  // Enhanced interpolation method
  interpolateValue(prev, next) {
    if (prev === null || prev === undefined) return next;
    if (next === null || next === undefined) return prev;
    //return whole number
    return Math.round((prev + next) / 2);
  }

  // Get statistics about filtered spikes
  getFilterStatistics(originalData, filteredData) {
    const stats = {};

    this.config.fieldsToAnalyze.forEach(field => {
      const changes = originalData.reduce((acc, original, index) => {
        const originalValue = this.getFieldValue(original, field);
        const filteredValue = this.getFieldValue(filteredData[index], field);
        
        if (originalValue !== filteredValue) {
          acc.spikes++;
          acc.totalDiff += Math.abs(originalValue - (filteredValue || 0));
        }
        return acc;
      }, { spikes: 0, totalDiff: 0 });

      stats[field] = {
        totalMeasurements: originalData.length,
        spikesDetected: changes.spikes,
        spikePercentage: (changes.spikes / originalData.length * 100).toFixed(2) + '%',
        averageAdjustment: changes.spikes > 0 ? (changes.totalDiff / changes.spikes).toFixed(2) : 0
      };
    });

    return stats;
  }
}

export default DataSpikeFilter;