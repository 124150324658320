/*
 | Routes
 | ---------
 | Use constants for router paths so we can
 | easily change it later
 */
export const PATH_HOME = '/'
export const PATH_ACCOUNT = '/account'
export const PATH_WORKSPACE = '/workspace'
export const PATH_ASSETS = '/assets'
export const PATH_EXPLORE = '/explore'
export const PATH_SUMMARY = '/summary'
export const PATH_DETAIL = '/detail'

export const GAUGES = [
  { key: 'workspace_utilization', name: 'Workspace Utilization', unit: '%', min: 0, max: 100 },
  { key: 'meeting_utilization', name: 'Meeting Utilization', unit: '%', min: 0, max: 100 },
  { key: 'mic', name: 'Sound', unit: 'dB', min: 0, max: 60 },
  { key: 'temp', name: 'Temperature', unit: '°C', min: 15, max: 28 },
  { key: 'lux', name: 'Light', unit: 'lux', min: 350, max: 550 },
  { key: 'humidity', name: 'Humidity', unit: '%', min: 10, max: 70 },
  { key: 'tvoc', name: 'TVOC', unit: 'ppb', min: 0, max: 450 },
  { key: 'co2', name: 'CO2', unit: 'ppm', min: 800, max: 1200 },
  { key: 'count', name: 'Count', unit: '', min: 0, max: 100 },
  { key: 'fixedAssets', name: 'Static Assets', unit: '%', min: 50, max: 100 },
  { key: 'mobileAssets', name: 'Mobile Assets', unit: '%', min: 50, max: 100 }
]

export const STATUS = {
  OK: 1,
  WARNING: 2,
  CONSIDERABLE: 3,
  DANGEROUS: 4, 
  ERROR: 5
}

export const HEALTH_STATUS = {
  OK: 'OK',
  WARNING: 'WARNING',
  CRITICAL: 'CRITICAL'
}

export const RANGE = {
  MONTH: 30,
  WEEK: 7,
  DAY: 1,
  HOUR: 0
}

export const SENSOR_TYPE = {
  PEBBLE: 1,
  DOC: 2,
  ABACUS: 3,
  POINTGRAB: 4,
  AIRLAB: 5,
  BUTTON: 7
}

export const PATH = {
  HOME: '/',
  ACCOUNT: '/account',
  LOGIN: '/login',

  EVENTS: '/events',

  // Sensors
  SENSORS: '/devices',
  SENSORS_LIST: '/devices/management',
  SENSORS_DETAIL: '/devices/management/%s',
  DEVICES_EVENTS: '/devices/events',

  // Reporting
  REPORTING_UTILIZATION_SPACES: '/reporting/utilization/spaces',
  REPORTING_UTILIZATION_ROOMS: '/reporting/utilization/rooms',
  REPORTING_UTILIZATION_TRAFFIC: '/reporting/utilization/traffic',
  REPORTING_HUMAN_EXPERIENCE_COMFORT: '/reporting/human-experience/comfort',
  REPORTING_AIR_QUALITY_HEALTH: '/reporting/air-quality/health',
  REPORTING_AIR_QUALITY_INDEX: '/reporting/aqi',
  REPORTING_HUMAN_EXPERIENCE_PRODUCTIVITY: '/reporting/human-experience/productivity',
  REPORTING_AREA_BREAKDOWN: '/reporting/area-breakdown',
  REPORTING_ANALYTICS: '/reporting/analytics',
  REPORTING_REPORT: '/reporting/report',
  REPORTING_STD_BANK_UTILIZATION: '/reporting/std-bank-utilization', // CUSTOM - STD BANK DASHBOARD
  REPORTING_TECHDATA_OCC: '/reporting/techdata-occupancy', // CUSTOM - Tech Data Demo
  REPORTING_TECHDATA_AIR: '/reporting/techdata-air', // CUSTOM - Tech Data Demo
  REPORTING_AMSTERDAM: '/reporting/amsterdam-demo', // CUSTOM - Amsterdam demo
  REPORTING_GYM: '/reporting/gym', // CUSTOM - Amsterdam demo
  REPORTING_DIDATA: '/reporting/didata', // CUSTOM - Amsterdam demo
  REPORTING_SILO: '/reporting/silo', // CUSTOM - Amsterdam demo

  // Account
  ACCOUNT_PROFILE: '/account/profile',
  ACCOUNT_NOTIFICATIONS: '/account/notifications',
  ACCOUNT_INTEGRATIONS: '/account/integrations',
  ACCOUNT_MEMBERS: '/account/members',
  ACCOUNT_COMPANIES: '/account/companies',
  ACCOUNT_DATA_REFACTORING: '/account/dataRefactoring',

  // Workspace
  WORKSPACE_BUILDING: '/workspace/buildings',
  WORKSPACE_FLOORS: '/workspace/floors',
  WORKSPACE_AREAS: '/workspace/areas',
  WORKSPACE_VIEWER: '/workspace/viewer',

  // Sensy
  SENSY_SURVEYS: '/sensy/surveys',
  SENSY_SURVEYS_DETAIL: '/sensy/surveys/%s',
  COVID_STATUS: '/sensy/covid',
  SENSY_NOTIFICATIONS: '/sensy/notifications',
  SENSY_SUGGESTIONS: '/sensy/suggestions',
  SENSY_SUGGESTION_DETAIL: '/sensy/suggestions/%s',
  SENSY_FIXIT: '/sensy/fixit',
  SENSY_FIXIT_DETAIL: '/sensy/fixit/%s',

  EDITOR: '/editor/%s',

  // Workflow automation
  FLOW_EDITOR: '/flow-editor',
  FLOW_EDITOR_ADD: '/flow-editor/new',
  FLOW_EDITOR_DETAIL: '/flow-editor/%s'
}

/**
 * Permission constant map.
 *
 * @type {object}
 */
export const PERMISSION = {
  ADMIN: 'admin',
  ERGOSENSE: 'ergosense'
}

/**
 * Survey types
 *
 * @type {object}
 */
export const SURVEY_TYPE = {
  MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
  SCALE: 'SCALE'
}
