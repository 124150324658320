import notificationActions from './../actions/notification'
import dayjs from 'dayjs'
import { string } from 'yup'
import { I18n } from '@aws-amplify/core'
import assert from 'assert'

export const isBatteryPowered = (type) => {
  return type === 1
}

export const canDeviceReset = (type) => {
  return (type === 2 || type === 3 || type === 5)  

}

export const deviceHasPublishInterval = (type) => {
  return (type === 2 || type === 3)
}

export const wrapRequest = (dispatch, promise, message = null) => {
  return promise
    .then((res) => {
      return res
    })
    .then((res) => {
      if (message) dispatch(notificationActions.success(message))
      return res
    })
    .catch((err) => {
      dispatch(notificationActions.error(err.message))
      throw err
    })
}

/**
 * Validation schema for emails
 */
export const emailSchema = string().required(I18n.get('validation.emailRequired')).email(I18n.get('validation.emailInvalid'))

/**
 * Validation schema for passwords
 */
export const passwordSchema = string().required(I18n.get('validation.passwordRequired'))

const helpers = {
  indexer: (data, key, multi = false) => {
    return data.reduce((accum, value) => {
      accum[value[key]] = multi ? (accum[value[key]] || []).concat([value]) : value
      return accum
    }, {})
  },
  dispatchError: (dispatch, err, swallow = true) => {
    dispatch(notificationActions.error(err.message))
    if (!swallow) throw err
  },
  formatDateTime: (value) => {
    if (!value) return '-'
    return dayjs.unix(value).format('DD/MM/YY H:mm:ss')
  }
}

export default helpers

export const updateArrayIndex = (array, desiredIndex, update) => {
  return array.map((i, index) => {
    if (index !== desiredIndex) return i
    return { ...i, ...update }
  })
}

export const indexer = (data, key, multi = false) => {
  return data.reduce((accum, value) => {
    accum[value[key]] = multi ? (accum[value[key]] || []).concat([value]) : value
    return accum
  }, {})
}

export const avatarCharacter = (str) => str && str.substr(0, 1).toUpperCase()

export const preventDefault = (e) => e.preventDefault()

export const canSubmit = (initialValues, values, errors) => {
  try {
    assert.deepStrictEqual(initialValues, values)
    return false
  } catch {
    return !(Object.keys(errors).length)
  }
}

export const createPaging = (filters, rowsPerPage) => {
  return { ...filters, start: Math.max((filters.start - 1), 0) * rowsPerPage, fetch: rowsPerPage }
}

export const getFiltersFromState = (state) => {
  const { rowsPerPage } = state.table

  const filters = { ...state.router.location.query }

  return { ...filters, start: Math.max((filters.start - 1), 0) * rowsPerPage, fetch: rowsPerPage }
}

export const getActiveCompanyId = (state) => {
  const { _user, auth } = state
  const companyId = (_user && _user.impersonate && _user.impersonate.companyId) || (auth.payload && auth.payload.companyId)

  return companyId
}

export const kFormatter = (num) => {
  return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
}
export const getAveragesByGroup = (arr, key, val, newkey) => {
  const average = (a, b, _i, self) => Math.round(a + b[val] / self.length)
  return Object.values(
    arr.reduce((acc, elem, _i, self) => (
      (acc[elem[key]] = acc[elem[key]] || {
        [key]: elem[key],
        [newkey]: self.filter((x) => x[key] === elem[key]).reduce(average, 0)
      }), acc), {})
  )
}
