import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import DataSpikeFilter from '../actions/DataSpikeFilter';
import Switch from '@material-ui/core/Switch';

// Create context
const SpikeFilterContext = createContext(null);

// Provider component
export const SpikeFilterProvider = ({ children }) => {
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    // Load saved state from cookies
    const savedState = document.cookie
      .split('; ')
      .find(row => row.startsWith('spikeFilterEnabled='));

    if (savedState) {
      const enabled = savedState.split('=')[1] === 'true';
      setIsEnabled(enabled);
    }
  }, []);

  const toggleFilter = (checked) => {
    setIsEnabled(checked);
    // Store the new state in cookies
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 365);
    document.cookie = `spikeFilterEnabled=${checked}; expires=${expiryDate.toUTCString()}; path=/`;
  };

  return (
    <SpikeFilterContext.Provider value={{ isEnabled, toggleFilter }}>
      {children}
    </SpikeFilterContext.Provider>
  );
};

// Custom hook to access the context
export const useSpikeFilter = () => {
  const context = useContext(SpikeFilterContext);
  if (!context) {
    throw new Error('useSpikeFilter must be used within a SpikeFilterProvider');
  }
  return context;
};

// Toggle component
export const SpikeFilterToggle = () => {
  const { isEnabled, toggleFilter } = useSpikeFilter();

  return (
    <div className="flex items-center space-x-2">
      <Switch
        checked={isEnabled}
        onChange={(event) => toggleFilter(event.target.checked)}
        id="spike-filter"
      />
      <label
        htmlFor="spike-filter"
        className="text-sm font-medium leading-none cursor-pointer"
      >
        {isEnabled ? 'Spike Filter On' : 'Spike Filter Off'}
      </label>
    </div>
  );
};

// Data filtering hook
export const useFilterData = (originalData) => {
  const { isEnabled } = useSpikeFilter();
  
  // Memoize the filtered data
  return useMemo(() => {
    // If filter is disabled or no data, return original data
    if (!isEnabled || !originalData) {
      return originalData;
    }

    try {
      const config = {
        fieldsToAnalyze: ['co2', 'tvoc', 'pm1', 'pm2', 'humidity', 'temp', 'tvoc'],
        interpolateSpikes: true,
      };

      const dataSpikeFilter = new DataSpikeFilter(config);
      
      // Create a deep copy of original data before filtering
      const dataToFilter = JSON.parse(JSON.stringify(originalData));
      return dataSpikeFilter.filterSpikes(dataToFilter);
    } catch (error) {
      console.error('Error applying spike filter:', error);
      return originalData;
    }
  }, [isEnabled, originalData]);
};